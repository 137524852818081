import React from 'react'
import styled from 'styled-components'
import { Container, Error, StyledInput } from './Input'

const StyledTextArea = styled(StyledInput).attrs({
  as: 'textarea',
})`
  resize: vertical;
`

const TextArea = ({ name, value, onChange, ...props }) => {
  return (
    <Container>
      <StyledTextArea name={name} id={name} value={value} onChange={onChange} {...props} rows={8} />
      {!!props.error && <Error>{props.error}</Error>}
    </Container>
  )
}

export default TextArea
