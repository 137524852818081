import React from 'react'
import { lawyers } from '../../assets/data'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Container = styled.div`
  margin-top: 5rem;
`

const Header = styled.h2`
  text-align: center;
  margin: 10px 20px;
  font-weight: 400;
`

const ContainerServices = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    width: 100%;
    max-width: 250px;
    height: 250px;
    background-color: ${(props) => props.theme.colors.white};
    margin: 1rem;
    /* box-shadow: 3px 3px 10px 3px ${(props) => props.theme.colors.goldLightestTransparent}; */
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    border: 1px solid ${(props) => props.theme.colors.goldLighter};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    svg {
      max-width: 3rem;
      opacity: 0.65;
    }
    h3 {
      text-align: center;
      display: block;
      width: 100%;
      position: relative;
      margin-top: 2rem;
      font-weight: 500;
      &::before {
        content: '';
        position: absolute;
        top: -1rem;
        left: 2rem;
        width: calc(100% - 4rem);
        height: 2px;
        background-color: ${(props) => props.theme.colors.gold};
      }
    }
    a {
      text-decoration: none;
      transition: color 0.1s;
      color: inherit;
      font-size: 14px;
      opacity: 0.7;
      &:hover {
        color: ${(props) => props.theme.colors.gold};
      }
    }
  }
`

const LawyerServices = ({ lawyerId, services }) => {
  const lawyer = lawyers.find((lawyer) => lawyer.id === lawyerId)
  return (
    <Container>
      <Header>
        {lawyer.prefix} {lawyer.name}
      </Header>
      <ContainerServices>
        {services.map((service) => (
          <div key={service.title}>
            {service.icon}
            <h3>{service.title}</h3>
            <AnchorLink to={`/${lawyer.slug}#${service.slug}`}>Zobacz więcej</AnchorLink>
          </div>
        ))}
      </ContainerServices>
    </Container>
  )
}

export default LawyerServices
