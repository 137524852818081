import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import Input from '../../components/Input'
import { validate } from './Helpers'
import TextArea from '../../components/TextArea'
import Button from '../../components/Button'
import LawyerSelect from './LawyerSelect'
import { submitFormData } from './submitFormData'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    padding: 2rem;
  }
  > div {
    margin: 2rem;
    box-shadow: 0 4px 20px 0 rgba(235, 209, 158, 0.5);
    padding: 0.5rem;
  }
  @media screen and (min-width: 900px) {
    flex-direction: row-reverse;
    form {
      width: 100%;
    }
    div {
      width: 100%;
      max-width: 380px;
      height: 100%;
    }
  }
`

const initialValues = {
  name: '',
  email: '',
  tel: '',
  lawyer: '',
  message: '',
}

const ContactFrom = () => {
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) =>
          submitFormData(values).then((res) => {
            if (res.success) resetForm()
            return res.success
          })
        }
        validate={validate}>
        {({ values, handleChange, handleSubmit, isSubmitting, errors, handleBlur, touched }) => (
          <form onSubmit={handleSubmit}>
            <Input
              name="name"
              type="text"
              value={values.name}
              onChange={handleChange}
              placeholder="Imię i nazwisko"
              onBlur={handleBlur}
              error={touched.name && errors.name}
            />
            <Input
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              placeholder="E-mail"
              onBlur={handleBlur}
              error={touched.email && errors.email}
            />
            <Input
              name="tel"
              type="tel"
              value={values.tel}
              onChange={handleChange}
              placeholder="Numer telefonu"
              onBlur={handleBlur}
              error={touched.tel && errors.tel}
            />
            <LawyerSelect
              name="lawyer"
              type="text"
              value={values.lawyer}
              onChange={handleChange}
              placeholder="Wybierz adwokata"
              error={touched.lawyer && errors.lawyer}
            />
            <TextArea
              name="message"
              value={values.message}
              onChange={handleChange}
              placeholder="Wiadomość"
              onBlur={handleBlur}
              error={touched.message && errors.message}
            />
            <Button type="submit">Wyślij</Button>
          </form>
        )}
      </Formik>
      <div>
        <iframe
          width="100%"
          height="370"
          scrolling="no"
          src="https://www.openstreetmap.org/export/embed.html?bbox=22.55418062210083%2C51.24418729365467%2C22.556691169738773%2C51.245305540538936&amp;layer=mapnik&amp;marker=51.24474616209919%2C22.5554358959198"
          style={{ border: '0' }}
          title="map"
        />
      </div>
    </Container>
  )
}

export default ContactFrom
