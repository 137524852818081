import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gold};
  height: 30px;
  position: relative;
  margin-bottom: 50px;
  h3 {
    position: absolute;
    top: 0.4rem;
    left: 10%;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: ${({ theme }) => theme.colors.black};
    font-size: 2rem;
    font-weight: 600;
    z-index: 5;
    margin: 0;
    padding: 0;
  }
`

const Triangle = styled.div`
  overflow: hidden !important;
  width: 100%;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    border-bottom: 30px solid transparent;
    border-top: 30px solid transparent;
    border-left: calc(100vw - 20px) solid #eaeae5;
    border-right: 0;
    overflow: hidden;
    opacity: 0.6;
  }
`

const Header = ({ label, className }) => {
  return (
    <Container className={className}>
      <Triangle />
      <h3>{label}</h3>
    </Container>
  )
}

export default Header
