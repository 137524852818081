import React from 'react'
import Header from '../../components/Header'
import { lawyers } from '../../assets/data'
import LawyerInfo from './LawyerInfo'

const AboutUs = () => {
  return (
    <div id="o-nas">
      <Header label="O nas" />
      {lawyers.map((lawyer) => (
        <LawyerInfo key={lawyer.name} {...lawyer} />
      ))}
    </div>
  )
}

export default AboutUs
