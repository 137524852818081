import React from 'react'
import styled from 'styled-components'
import PhoneIcon from '../../assets/icons/phone.inline.svg'
import EmailIcon from '../../assets/icons/email.inline.svg'
import WebsiteIcon from '../../assets/icons/website.inline.svg'

const Container = styled.div`
  margin-bottom: 3rem;
  img {
    width: 80%;
    object-fit: cover;
    aspect-ratio: 10 / 12;
    max-width: 500px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

const Info = styled.div`
  margin: -1.5rem 0 0 2rem;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  position: relative;
  z-index: 1;
  max-width: 500px;
  p {
    padding: 0 0 0.5rem 0;
    margin: 0;
  }
  a {
    display: block;
    margin: 0;
    color: inherit;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 14px;
    &:hover {
      color: ${(props) => props.theme.colors.darkGold};
    }
  }
`
export const ContactLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0;

  a {
    word-break: break-word;
  }

  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.gold};
  }
`

const Card = ({ prefix, name, photo, phone, email, website }) => {
  return (
    <Container>
      <img src={photo} alt={`${prefix} ${name}`} />
      <Info>
        <p>
          {prefix} {name}
        </p>
        <ContactLine>
          <PhoneIcon />
          <a href={`tel:${phone}`}>{phone}</a>
        </ContactLine>
        <ContactLine>
          <EmailIcon />
          <a href={`mailto:${email}`}>{email}</a>
        </ContactLine>
        <ContactLine>
          <WebsiteIcon />
          <a href={`https://${website}`}>{website}</a>
        </ContactLine>
      </Info>
    </Container>
  )
}

export default Card
