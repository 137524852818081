import React from 'react'
import { Container, Error, StyledInput } from '../../components/Input'
import { lawyers } from '../../assets/data'
import styled from 'styled-components'

const StyledSelect = styled(StyledInput).attrs({
  as: 'select',
})`
  color: ${({ theme }) => theme.colors.gray};
  padding: 0.5rem 0;
  opacity: ${({ value }) => (value ? 1 : 0.6)};
`

const LawyerSelect = ({ name, value, onChange, ...props }) => {
  return (
    <Container>
      <StyledSelect name={name} id={name} value={value} onChange={onChange} {...props}>
        <option value="" disabled>
          {props.placeholder}
        </option>
        {lawyers.map((lawyer) => (
          <option key={lawyer.id} value={lawyer.email}>
            {lawyer.prefix} {lawyer.name}
          </option>
        ))}
      </StyledSelect>
      {!!props.error && <Error>{props.error}</Error>}
    </Container>
  )
}

export default LawyerSelect
