import React from 'react'
import Card from './Card'
import styled from 'styled-components'
import { lawyers } from '../../assets/data'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import DesktopCard from './DesktopCard'

const Container = styled.div`
  width: 100%;
  //background-color: ${(props) => props.theme.colors.grey};
  padding: 2rem;
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 900px) {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 6rem;
    max-width: 1250px;
    margin: -10rem auto 10rem;
  }
  @media screen and (min-width: 1100px) {
    margin: -15rem auto 6rem;
  }
  @media screen and (min-width: 1400px) {
    margin: -20rem auto 6rem;
  }
`

const CardsSection = () => {
  const { width } = useWindowDimensions()
  const isMobile = width < 900
  return (
    <Container>
      <InnerWrapper>
        {lawyers.map((lawyer) =>
          isMobile ? (
            <Card key={lawyer.name} {...lawyer} />
          ) : (
            <DesktopCard key={lawyer.name} {...lawyer} />
          )
        )}
      </InnerWrapper>
    </Container>
  )
}

export default CardsSection
