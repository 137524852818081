const nameRegex = /^.{3,}$/
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const telRegex = /^\s*((\+48)?|(\(\+48\))?)[-. ]?[5678]?(\d{2})[-. ]?(\d{3})[-. ]?(\d{3})[-. ]?\s*$/

export const validate = (values, props) => {
  const errors = {}

  if (!values.name.match(nameRegex)) errors.name = 'To pole musi zawierać conajmniej 3 znaki'
  if (!values.email.match(emailRegex)) errors.email = 'Niepoprawny adres email'
  if (!values.tel.match(telRegex)) errors.tel = 'Niepoprawny numer telefonu'
  if (values.message.length < 5) errors.message = 'To pole musi zawierać conajmniej 5 znaków'

  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      errors[key] = 'To pole jest wymagane'
    }
  })

  return errors
}
