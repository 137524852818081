import styled from 'styled-components'

const Button = styled.button`
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.gold};
  background-color: transparent;
  padding: 0.5rem 1rem;
  align-self: flex-start;
  color: ${(props) => props.theme.colors.darkGrey};
  transition: color 0.2s, border-bottom-width 0.2s;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.black};
    border-bottom-width: 3px;
  }
`

export default Button
