import React from 'react'
import styled from 'styled-components'
import LawyerCard from './LawyerCard'
import { lawyers } from '../../assets/data'
import { Link } from 'gatsby'
import razem from '../../assets/images/razem.jpg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  > img {
    width: 100%;
    max-width: 900px;
    margin: 2rem auto 4rem;
  }
`
const CardsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.goldLightestTransparent};
  padding: 3rem 1rem;
`
const InnerContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: grid;
  grid-template: 1fr 1fr 1fr / 4fr 6fr;
  grid-gap: 1rem;
`
const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1rem;
`
const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: inherit;
  }
`
const CreatedBy = styled.div`
  margin-top: 2rem;
  div {
    text-align: right;
    p {
      display: inline;
      margin-right: 4px;
    }
    a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.gold};
    }
  }
`

const Footer = () => (
  <Container>
    <img src={razem} alt="Adwokaci Orla 3" />
    <CardsContainer>
      <InnerContainer>
        {lawyers.map((lawyer) => (
          <LawyerCard key={lawyer.name} {...lawyer} />
        ))}
      </InnerContainer>
    </CardsContainer>
    <FooterContainer>
      <Nav>
        <Link to="/">Strona główna</Link>
        <Link to="/polityka-prywatnosci">
          Polityka prywatności <br />i pliki cookies
        </Link>
      </Nav>
      <CreatedBy>
        <div>
          <p>Graphic design/tests</p>
          <a href="https://github.com/aMaletka" target="_blank" rel="noreferrer">
            Małetka Anna
          </a>
        </div>
        <div>
          <p>Code by</p>
          <a href="https://github.com/grzywaczek" target="_blank" rel="noreferrer">
            Grzywacz Anna
          </a>
        </div>
      </CreatedBy>
    </FooterContainer>
  </Container>
)

export default Footer
