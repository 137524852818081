import React from 'react'
import styled from 'styled-components'
import backgroundImage from '../assets/images/wejscie.jpg'
import useWindowDimensions from '../hooks/useWindowDimensions'

const Image = styled.div`
  width: 100%;
  height: 25vh;
  position: relative;
  text-align: center;
  padding-top: 2rem;
  overflow: hidden;
  @media screen and (min-width: 750px) {
    height: 30vh;
  }
  @media screen and (min-width: 900px) {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
    &::before {
      display: none;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${backgroundImage}) no-repeat center 10%;
    background-size: cover;
  }
`

const HeroSection = () => {
  const { width } = useWindowDimensions()
  const isMobile = width < 900
  return !isMobile ? (
    <Image>
      <img src={backgroundImage} alt="Wejście" />
    </Image>
  ) : (
    <Image />
  )
}

export default HeroSection
