import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`
export const StyledInput = styled.input`
  padding: 0.5rem;
  outline: none;
  border: unset;
  border-bottom: 2px solid ${({ error }) => (!!error ? 'red' : '#666')};
  background-color: transparent;
  &::placeholder {
    color: ${(props) => props.theme.colors.darkGrey};
  }
  &:focus {
    border-bottom: 2px solid black;
  }
`
export const Error = styled.span`
  color: red;
  align-self: flex-end;
  font-size: 0.7rem;
`

const Input = ({ name, value, onChange, type = 'text', ...props }) => {
  return (
    <Container>
      <StyledInput type={type} name={name} id={name} value={value} onChange={onChange} {...props} />
      {!!props.error && <Error>{props.error}</Error>}
    </Container>
  )
}

export default Input
