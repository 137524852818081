import React from 'react'
import styled from 'styled-components'
import Header from '../../components/Header'
import { services } from '../../assets/data'
import LawyerServices from './LawyerServices'

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.whiteDarker};
  padding-bottom: 4rem;
`
const StyledHeader = styled(Header)`
  margin: 6rem 0 8rem;
`

const Services = () => {
  return (
    <Container id="uslugi">
      <StyledHeader label="Usługi" />
      {services.map((service) => (
        <LawyerServices key={service.lawyerId} {...service} />
      ))}
    </Container>
  )
}

export default Services
