import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import PhoneIcon from '../../assets/icons/phone.inline.svg'
import EmailIcon from '../../assets/icons/email.inline.svg'
import WebsiteIcon from '../../assets/icons/website.inline.svg'
import { ContactLine } from './Card'

const Background = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Photo = styled.div`
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    /* border-radius: 5px; */
    aspect-ratio: 10 / 12;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
  div {
    position: absolute;
    top: 15px;
    left: 10%;
    right: 10%;
    text-align: center;
    h3 {
      color: ${({ theme }) => theme.colors.black};
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }
`

const Contact = styled.div`
  width: 100%;
  height: 155px;
  position: relative;
  perspective: 500px;
  margin-top: 30px;
  > div.content {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    box-shadow: -4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &.animated .content {
    transform: rotateY(180deg);
    transition: transform 0.5s;
  }
`

const Front = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`

const Back = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 25px;
  div {
    flex: 1;
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

const DesktopCard = ({ photo, prefix, name, phone, email, website, cardFront }) => {
  const containerRef = useRef(null)
  const cardRef = useRef(null)
  useEffect(() => {
    if (!containerRef.current || !cardRef.current) return

    const elementHasIntersected = (entries) => {
      if (entries[0].isIntersecting) cardRef.current.classList.add('animated')
    }

    const ioConfiguration = {
      rootMargin: '10% 0% -90% 0%',
      threshold: 0,
    }
    const observer = new IntersectionObserver(elementHasIntersected, ioConfiguration)
    const containerNode = containerRef.current
    observer.observe(containerNode)
    return () => {
      observer?.unobserve(containerNode)
    }
  }, [])

  return (
    <Background ref={containerRef}>
      <Photo>
        <img src={photo} alt={`${prefix} ${name}`} />
        <div>
          <h3>{prefix}</h3>
          <h3>{name}</h3>
        </div>
      </Photo>
      <Contact ref={cardRef}>
        <div className="content">
          <Front>
            <img src={cardFront} alt="wizytowka" />
          </Front>
          <Back>
            <ContactLine>
              <PhoneIcon />
              <a href={`tel:${phone}`}>{phone}</a>
            </ContactLine>
            <ContactLine>
              <EmailIcon />
              <a href={`mailto:${email}`}>{email}</a>
            </ContactLine>
            <ContactLine>
              <WebsiteIcon />
              <a href={`https://${website}`}>{website}</a>
            </ContactLine>
          </Back>
        </div>
      </Contact>
    </Background>
  )
}

export default DesktopCard
