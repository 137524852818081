import * as React from 'react'
import MainTemplate from '../templates/MainTemplate'
import HeroSection from '../sections/HeroSection'
import CardsSection from '../sections/CardsSection'
import AboutUs from '../sections/AboutUs'
import Services from '../sections/Services'
import ContactSection from '../sections/ContactSection'
import FooterSection from '../sections/FooterSection'
import { Helmet } from 'react-helmet'

const IndexPage = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title>Adwokaci Orla 3 | Strona główna</title>
        <meta
          name="description"
          content="Kancelaria Adwokacka Orla 3 Lublin prowadzona przez trzech adwokatów Adw. Diana Walczak-Bogusz, Adw. Julianna Kędzierska, Adw. Martyna Pereta"
        />
        <meta name="robots" content="follow, index" />
      </Helmet>
      <HeroSection />
      <CardsSection />
      <AboutUs />
      <Services />
      <ContactSection />
      <FooterSection />
    </MainTemplate>
  )
}

export default IndexPage
