import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  width: 100%;
  box-shadow: 0 0 3px 0 ${(props) => props.theme.colors.goldLightest};
`
const Card = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 0 3px 0 ${(props) => props.theme.colors.goldLightest};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  a {
    text-decoration: none;
    color: inherit;
  }
  p {
    font-size: 0.9rem;
    margin: 0;
    &:first-of-type {
      font-size: 1rem;
    }

    @media screen and (min-width: 900px) {
      &:first-of-type {
        font-size: 1.1rem;
      }
    }
  }
`
const NameLine = styled.div`
  span {
    font-weight: 600;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 900px) {
    flex-direction: row;
    span {
      padding-left: 4px;
    }
  }
`

const LawyerCard = ({ cardFront, prefix, name, nip, regon, email, phone }) => (
  <>
    <Image src={cardFront} alt="wizytówka" />
    <Card>
      <NameLine>
        <p>{prefix}</p>
        <span> {name}</span>
      </NameLine>
      <p>
        Telefon: <a href={`tel:${phone}`}>{phone}</a>
      </p>
      <p>
        E-mail: <a href={`mailto:${email}`}>{email}</a>
      </p>
      <p>NIP: {nip}</p>
      <p>REGON: {regon}</p>
    </Card>
  </>
)

export default LawyerCard
