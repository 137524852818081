import React from 'react'
import styled from 'styled-components'
import ScaleIcon from '../../assets/icons/scale.inline.svg'
import PhoneIcon from '../../assets/icons/phone.inline.svg'
import EmailIcon from '../../assets/icons/email.inline.svg'
import WebsiteIcon from '../../assets/icons/website.inline.svg'

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  p {
    text-align: justify;
    margin-bottom: 2rem;
    line-height: 28px;
    widows: 3;
    orphans: 3;
  }
  svg {
    width: 50px;
    margin: 30px 0;
    color: ${({ theme }) => theme.colors.gold};
  }
  h2 {
    text-align: center;
    font-weight: 400;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 auto 2rem;
  max-width: 1192px;
  width: 95%;
  &:last-child {
    margin-bottom: 4rem;
  }
  img {
    width: 100%;
  }
  @media screen and (min-width: 900px) {
    flex-direction: row;
    ${InfoContainer} {
      width: 50%;
      padding-left: 2rem;
    }
    img {
      width: 50%;
      padding-right: 2rem;
      padding-left: 0;
      object-fit: cover;
    }
    &:nth-of-type(2n + 1) {
      flex-direction: row-reverse;
      img {
        padding-left: 2rem;
        padding-right: 0;
      }

      ${InfoContainer} {
        padding-left: 0;
        padding-right: 2rem;
      }
    }
  }
`

const Contact = styled.div`
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 2px solid ${(props) => props.theme.colors.gold};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    @media screen and (min-width: 900px) {
      &:nth-of-type(2) {
        justify-content: center;
      }

      &:last-of-type {
        justify-content: flex-end;
      }
    }
    svg {
      width: 25px;
      margin: 0 10px 0 0;
    }
  }
`

const LawyerInfo = ({ photo2: photo, prefix, name, description, phone, email, website }) => {
  return (
    <Container>
      <img src={photo} alt={name} />
      <InfoContainer>
        <h2>
          {prefix} {name}
        </h2>
        <ScaleIcon />
        <p>{description}</p>
        <Contact>
          <a href={`tel:${phone}`}>
            <PhoneIcon />
            {phone}
          </a>
          <a href={`mailto:${email}`}>
            <EmailIcon />
            {email}
          </a>
          <a href={`https://${website}`} target="_blank" rel="noreferrer">
            <WebsiteIcon />
            {website}
          </a>
        </Contact>
      </InfoContainer>
    </Container>
  )
}

export default LawyerInfo
