import React from 'react'
import styled from 'styled-components'
import Header from '../../components/Header'
import ContactFrom from './ContactFrom'

const Container = styled.div``

const ContactSection = () => {
  return (
    <Container id="kontakt">
      <Header label="Kontakt" />
      <ContactFrom />
    </Container>
  )
}

export default ContactSection
